import React, { ReactElement } from 'react';
import style from './sensor-list.module.scss';
import rowStyle from './sensor-row.module.scss';
import { useHistory } from 'react-router-dom';
import { SensorTile } from '../../controls/sensor-tile/sensor-tile';
import { Sensor, UserSensor } from '../../../api/contracts';
import { SensorRow } from './sensor-row';
import classNames from 'classnames';
import { CellGroup, HeaderRowCell, Row } from '../../controls/row/row';
import { SensorListDisplayOption, SensorOverviewAsyncDispatch } from '../../../redux/sensor-overview/types';
import { useDispatch, useSelector } from 'react-redux';
import { displayOptionSelector, sensorsSelector } from '../../../redux/sensor-overview/selectors';
import { thresholdSelector } from '../../../redux/account/selectors';
import { updateSensor } from '../../../redux/sensor-overview/actions';
import { notifyError, notifyErrorMessage } from '../../../utils/notify';
import { useAccess } from '../../../hooks/access';
import { AnimatedListContainer } from '../animated-list-container/animated-list-container';
import { useLoadingBarDispatch } from '../../../redux/loading-bar/effects';
import { SensorMiniTile } from '../../controls/sensor-mini-tile/sensor-mini-tile';
import _ from 'lodash';
import { SensorTilesSkeleton } from '../../mui/skeletons/SensorTilesSkeleton';
import { Box } from '@material-ui/core';


export interface SensorListProps {
    selectable?: boolean;
    displayOption?: SensorListDisplayOption;
    onSensorSelectedHandler?: (sensorId: string, selected: boolean) => void;
    selectedSensors?: Array<UserSensor>;
}


export const SensorList: React.FC<SensorListProps> = (props: SensorListProps) => {
    const sensors = useSelector(sensorsSelector);
    const threshold = useSelector(thresholdSelector);

    let displayOption = useSelector(displayOptionSelector);

    if(props.displayOption) {
        displayOption = props.displayOption
    }

    const history = useHistory();

    const sensorComponentForMiniTiles = (sensor: Sensor): ReactElement => {
        const isSelected = _.find(props.selectedSensors, userSensor => userSensor.sensor_id === sensor.sensor_id)

        return (
            <Box>
                <SensorMiniTile
                  key={sensor.sensor_id}
                  name={sensor.nickname}
                  sensorId={sensor.sensor_id}
                  selectable={props.selectable}
                  onClick={(selected) => sensorClickHandler(sensor.sensor_id, selected)}
                  isSelected={isSelected ? true : false}
                />
            </Box>
        );
    }

    const sensorComponentForTiles = (sensor: Sensor): ReactElement => {
        return (
            <SensorTile
                name={sensor.nickname}
                sensorId={sensor.sensor_id}
                binTypeName={sensor.bin_type?.name}
                batteryStatus={sensor.battery}
                fillStatus={sensor.fill_percentage}
                threshold={threshold}
                isPinned={sensor.pinned}
                key={sensor.sensor_id}
                lastReport={sensor.last_connected_on}
                battery={sensor.battery}
                signal={sensor.signal}
                muted={sensor.muted}
                selectable={props.selectable}
                onClick={() => sensorClickHandler(sensor.sensor_id, false)}
                onPinClick={toggleSensorPinningOnClickHandler(sensor)}
            />
        );
    }

    const sensorComponentForList = (sensor: Sensor): ReactElement => {
        return (
            <SensorRow
                key={sensor.sensor_id}
                sensorId={sensor.sensor_id}
                fillPercentage={sensor.fill_percentage}
                nextReport={sensor.next_report}
                type={sensor.bin_type?.name}
                name={sensor.nickname}
                location={sensor.location}
                lastReport={sensor.last_connected_on}
                isPinned={sensor.pinned}
                battery={sensor.battery}
                signal={sensor.signal}
                muted={sensor.muted}
                state={sensor.state}
                onClick={() => sensorClickHandler(sensor.sensor_id, false)}
                onPinClick={toggleSensorPinningOnClickHandler(sensor)}
            />
        );
    }


    const sensorClickHandler = (sensorId: string, selected: boolean): void => {
        if(props.selectable && props.onSensorSelectedHandler) {
            props.onSensorSelectedHandler(sensorId, selected)
        } else {
            history.push(`/sensors/${sensorId}`);
        }
    };

    const [incRC, decRC] = useLoadingBarDispatch();
    const sensorOverviewAsyncDispatch = useDispatch<SensorOverviewAsyncDispatch>();
    const hasAccess = useAccess('ADMIN');
    const toggleSensorPinningOnClickHandler = (sensor: Sensor) => (isPinned: boolean): void => {
        const body = { pinned: isPinned };

        if (hasAccess) {
            incRC();
            sensorOverviewAsyncDispatch(updateSensor(sensor.sensor_id, body))
                .then(() => decRC())
                .catch((e) => {
                    notifyError('Error updating sensor', notifyErrorMessage(e));
                    decRC();
                });
        }
    }

    return (
        <div className={classNames(style.SensorList)}>
            { displayOption === 'list'  &&
                <Row className={classNames(rowStyle.SensorRow, rowStyle.header)} rowClassName='header'>
                    <CellGroup>
                        <HeaderRowCell className={rowStyle.fillPctCell} rowCellClassName='static' hasBorder={true}>
                            Contamination %
                        </HeaderRowCell>
                        <HeaderRowCell className={rowStyle.nextReportCell} rowCellClassName='static' hasBorder={true}>
                            Next report
                        </HeaderRowCell>
                        <HeaderRowCell className={rowStyle.typeCell} rowCellClassName='flexible' hasBorder={true}>
                            Type
                        </HeaderRowCell>
                        <HeaderRowCell className={rowStyle.nameCell} rowCellClassName='flexible' hasBorder={true}>
                            Name
                        </HeaderRowCell>
                        <HeaderRowCell className={rowStyle.lastReportCell} rowCellClassName='static' hasBorder={false}>
                            Last report
                        </HeaderRowCell>
                        <HeaderRowCell className={rowStyle.batteryCell} rowCellClassName='static' hasBorder={true}>
                        </HeaderRowCell>
                    </CellGroup>
                    <HeaderRowCell className={rowStyle.optionsCell} rowCellClassName='options'/>
                </Row>
            }
            {
                sensors ? (
                    <Box>
                        {
                            displayOption === 'list' &&
                            // <AnimatedListContainer id='list' className={style.list}>
                            <Box className={style.list}>
                                {sensors.map((sensor) => sensorComponentForList(sensor))}
                            </Box>
                            // </AnimatedListContainer>
                        }
                        {
                            displayOption === 'tiles' &&
                            // <AnimatedListContainer id='tiles' className={style.tiles}>
                            <Box className={style.tiles}>
                                {sensors.map((sensor) => sensorComponentForTiles(sensor))}
                            </Box>
                            // </AnimatedListContainer>
                        }
                        {
                            displayOption === 'mini_tiles' &&
                            <Box
                              display="grid"
                              gridTemplateColumns="1fr 1fr"
                              gridColumnGap={20}
                              gridRowGap={20}
                            >
                                {sensors.map((sensor) => sensorComponentForMiniTiles(sensor))}
                            </Box>
                        }
                    </Box>
                ) : (
                    <SensorTilesSkeleton sensorListDisplayOption={displayOption}/>
                )
            }
        </div>
    );
};
