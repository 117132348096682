import React, { useMemo, useState } from 'react';
import style from './sensor-details.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { SensorChart } from '../../building-blocks/sensor-chart/sensor-chart';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { SensorDetailsHeader } from '../../building-blocks/sensor-details-header/sensor-details-header';
import { reportDataSelector } from '../../../redux/sensor-detail/selectors';
import { useSelector } from 'react-redux';
import { thresholdSelector } from '../../../redux/account/selectors';
import { useSensorData, useSensorReportsData } from '../../../redux/sensor-detail/effects';
import { useClientRefreshCallback } from '../../../redux/auth/effects';
import { SensorSettingsModal } from '../../building-blocks/sensor-settings-modal/sensor-settings-modal';
import { Access } from '../../controls/access/access';
import { SensorReport } from '../../../api/sensors-reports';
import { Box, Typography } from '@material-ui/core';
import { MuiButton } from '../../mui/MuiButton';
import { getLogoUrl } from '../../../restaurants';

export const SensorDetails: React.FC<{}> = () => {
  const { sensorId } = useParams() as any;

  const reportData = useSelector(reportDataSelector);
  const batteryData: Array<SensorReport> = reportData
    .filter((r) => r.battery === 0 || r.battery)
    .map((r) => ({ ...r, value: r.battery, model: undefined }));
  const signalData = reportData
    .filter((r) => typeof r.signal === "number" && r.signal <= 31)
    .map((r) => ({ ...r, value: r.signal, model: undefined }));
  const threshold = useSelector(thresholdSelector);

  const [sensor, setSensor] = useSensorData(sensorId || null);
  useSensorReportsData(sensorId || null);

  /** If client id is changed, then go back to previous page **/
  const history = useHistory();
  useClientRefreshCallback(() => {
    history.goBack();
  });

  const [displayedModalType, setDisplayedModalType] =
    useState<"none" | "settings" | "reset">("none");
  
    const logoUrl = useMemo(() => {
      if (!sensor) return undefined; // Return early if sensor is null
      return getLogoUrl(sensor.nickname, sensor.sensor_id);
    }, [sensor]);
  
    return (
    <InterfaceFrame>
      {sensor && (
          <Box
              display="flex"
              flexDirection="column"
          >
            {displayedModalType === "settings" && (
              <SensorSettingsModal
                sensor={sensor}
                setSensor={setSensor}
                onModalClose={() => setDisplayedModalType("none")}
              />
            )}
            <Box marginBottom={8}>
              <SensorDetailsHeader
                  fillStatus={sensor.fill_percentage || 0}
                  name={sensor.nickname || ""}
                  sensorId={sensor.sensor_id}
                  threshold={threshold}
                  lastReport={sensor.last_connected_on}
                  binTypeName={sensor.bin_type?.name}
                  battery={sensor.battery}
                  signal={sensor.signal}
                  muted={sensor.muted}
                  users={sensor.users}
                  logoUrl={logoUrl}
              />
  
              <Box marginBottom={2}>
                <Typography variant="h1">Contamination %</Typography>
              </Box>
              <SensorChart reports={reportData} threshold={threshold} />
              {/* <Access roleHierarchy="LIDBOT_SUPPORT">
                <Box marginBottom={2}>
                  <Typography variant="h1">Battery</Typography>
                </Box>
                <SensorChart
                    reports={batteryData}
                    threshold={20}
                    thresholdDirection="lt"
                />
                <Box marginBottom={2}>
                  <Typography variant="h1">Signal</Typography>
                </Box>
                <SensorChart
                    reports={signalData}
                    threshold={8}
                    thresholdDirection="lt"
                    yAxisMaxValue={32}
                    yAxisIncrement={8}
                />
              </Access> */}
            </Box>

            <Box>
              <div className={style.sensorSettingsControls}>
                <Access roleHierarchy="ADMIN">
                  <MuiButton
                    label="Settings"
                    color="default"
                    onClick={() => setDisplayedModalType('settings')}/>
                </Access>
              </div>
            </Box>
          </Box>
      )}
    </InterfaceFrame>
  );
};
