import React, { ReactElement } from "react";
import style from "./total-bags-panel.module.scss";
import { StatsPanel } from "../../controls/stats-panel/stats-panel";
import { WeekDataChart } from "../../controls/week-data-chart/week-data-chart";
import { StatsPanelStatus } from "../../controls/stats-panel-status/stats-panel-status";
import { useSelector } from "react-redux";
import {
  bagsChartDataSelector,
  totalBagsCountSelector,
} from "../../../redux/dashboard/selectors";
import { Trans } from "react-i18next";

export const TotalBagsPanel: React.FC<{}> = () => {
  const panelTitleElement = <span>Bags Collected Per Day</span>;

  const totalBagsCount = useSelector(totalBagsCountSelector);
  const bagsChartData = useSelector(bagsChartDataSelector);

  const getHelp = (): ReactElement => {
    return (
      <>
        <p>
          {
            "The Pick-up overview widget shows the total number of bin pick-ups registered in the previous 7 days."
          }
        </p>
      </>
    );
  };

  return (
    <StatsPanel title={panelTitleElement} help={getHelp()}>
      <div className={style.TotalBagsPanel}>
        <StatsPanelStatus value={totalBagsCount} />
        <WeekDataChart data={bagsChartData} />
      </div>
    </StatsPanel>
  );
};
