import { PasswordReset } from '../components/views/password-reset/password-reset';
import { Location } from '../components/views/location/location';
import { UsersSettings } from '../components/building-blocks/users-settings/users-settings';
import { BinTypeSettings } from '../components/views/bin-type-settings/bin-type-settings';
import { ReportSettings } from '../components/views/reports-settings/reports-settings';
import { UserProfile } from '../components/building-blocks/user-settings/user-profile';
import { SettingsPage } from '../components/views/settings/settings';
import { Dashboard } from '../components/views/dashboard/dashboard';
import { SensorDetails } from '../components/views/sensor-details/sensor-details';
import { SensorOverview } from '../components/views/sensor-overview/sensor-overview';
import { BinOverview } from '../components/views/bin-overview/bin-overview';
import { RouteProps } from 'react-router-dom';
import { Login } from '../components/views/login/login';
import { Verify } from '../components/views/verify/verify';
import { NotFound } from '../components/views/errors/not-found';
import { Schedule } from '../components/views/sensor-schedule/schedule';
import { SensorsSettings } from '../components/views/sensors-settings/sensors-settings';
import { Customers } from '../components/views/team/customers';
import { UserRoleType } from '../redux/user/types';
import { ApiKeys } from '../components/views/api-keys/api-keys';


/**
 * Interface for the routes used in the Application.
 * Extends the default RouteProps from react-router-dom.
 */
export interface AppRouteProps extends RouteProps {
	/* Defines whether the route is private and thus requires authentication or not */
	protected?: boolean;
	/* Defines whether the route is only for support*/
	roleHierarchy?: UserRoleType;
}

/**
 * Defines the interface for the configuration of the application routes.
 * Any route is named by its key (must be a string) and is defined by the AppRouteProps.
 */
export interface AppRouteDefinitions {
	[key: string]: AppRouteProps;
}

/**
 * The routes configuration for the application.
 */
export const appRoutes: AppRouteDefinitions = {
	login: {
		path: '/login',
		exact: true,
		component: Login,
	},
	verify: {
		path: '/verify/:email/:password/:language',
		exact: true,
		component: Verify,
	},
	resetPassword: {
		path: '/reset-password/:username?/:code?/:language?',
		exact: true,
		component: PasswordReset,
	},
	dashboard: {
		path: '/',
		protected: true,
		exact: true,
		component: Dashboard,
	},
	bins: {
		path: '/bins',
		protected: true,
		exact: true,
		component: BinOverview,
	},
	sensorList: {
		path: '/sensors',
		protected: true,
		exact: true,
		component: SensorOverview,
	},
	sensorDetails: {
		path: '/sensors/:sensorId',
		protected: true,
		exact: true,
		component: SensorDetails,
	},
	reports: {
		path: '/reports',
		protected: true,
		component: ReportSettings,
	},
	location: {
		path: '/location',
		protected: true,
		component: Location,
	},
	settings: {
		path: '/settings',
		protected: true,
		component: SettingsPage,
	},
	teams: {
		path: '/customers',
		roleHierarchy: 'LIDBOT_SUPPORT',
		exact: true,
		component: Customers,
	},
	user: {
		path: '/profile',
		protected: true,
		exact: true,
		component: UserProfile,
	},
	notfound: {
		path: '*',
		component: NotFound,
	},
};

export const settingsRoutes: AppRouteDefinitions = {
	// binTypes: {
	// 	path: '/settings/bin-models',
	// 	protected: true,
	// 	exact: true,
	// 	component: BinTypeSettings,
	// },
	users: {
		path: '/settings/users',
		protected: true,
		exact: true,
		component: UsersSettings,
	},
	// sensors: {
	// 	path: '/settings/sensors',
	// 	protected: true,
	// 	exact: true,
	// 	component: SensorsSettings,
	// },
	schedule: {
		path: '/settings/schedule',
		protected: true,
		exact: true,
		component: Schedule,
	},
	apiKeys: {
		path: '/settings/api-keys',
		roleHierarchy: 'DEVELOPER',
		exact: true,
		component: ApiKeys,
	},
};

export const adminRoutes: AppRouteDefinitions = {};
