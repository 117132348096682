import React from 'react';
import style from './settings-navigation.module.scss';
import { SettingsNavigationLink, SettingsNavigationLinkProps } from '../settings-navigation-link/settings-navigation-link';
import { useSelector } from 'react-redux';
import { roleSelector } from '../../../redux/auth/selectors';
import userRoleHierarchy from '../../../config/userRoleHierarchy';
import { Box } from '@material-ui/core';

const settingsNavigationLinks: Array<SettingsNavigationLinkProps> = [
  // {
  //   to: "/settings/sensors",
  //   label: "Sensors",
  //   onlyActiveWhenExact: true,
  // },
  {
    to: "/settings/users",
    label: "Users",
    i18nKey: "settings.navigation.users",
    onlyActiveWhenExact: true,
  },
  // {
  //   to: "/settings/bin-models",
  //   label: "Bin Models",
  //   onlyActiveWhenExact: true,
  // },
  {
    to: "/settings/api-keys",
    label: "API Keys",
    onlyActiveWhenExact: true,
    roleHierarchy: "DEVELOPER",
  },
];

export const SettingsNavigation: React.FC<{}> = () => {
  const role = useSelector(roleSelector);

  const roleAccessibleSettingsNavigationLinks = settingsNavigationLinks.filter(
    (link) => {
      return link.roleHierarchy
        ? role && userRoleHierarchy[link.roleHierarchy].includes(role)
        : true;
    }
  );
  return (
    <Box mb={4} className={style.SettingsNavigation}>
      <ul>
        {roleAccessibleSettingsNavigationLinks.map((link) => (
          <li key={link.to}>
            <SettingsNavigationLink {...link} />
          </li>
        ))}
      </ul>
    </Box>
  );
};
