import React from 'react';
import { InterfaceFrame } from '../../building-blocks/interface-frame/interface-frame';
import { SettingsRouter } from '../../router/settings-router';
import { SettingsNavigation } from '../../controls/settings-navigation/settings-navigation';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

export const SettingsPage: React.FC<{}> = () => {
  const isActive = useRouteMatch({
    path: "/settings",
    exact: true,
  });
  return (
    <InterfaceFrame>
      {isActive && <Redirect to="/settings/users" />}
        <Box marginBottom={2}>
            <Typography variant="h1">Settings</Typography>
        </Box>
      <SettingsNavigation />
      <SettingsRouter />
    </InterfaceFrame>
  );
};
