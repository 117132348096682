/* eslint-disable max-len */
export default {
  translation: {
    sidebar: {
      overview: "Dashboard",
      sensor: "Sensor",
      location: "Location",
      settings: "Settings",
      logout: "Logout",
    },
    loginPage: {
      email: "Email",
      password: "Password",
      passwordForgotten: "Forgot your password?",
      login: "Login",
      panelHeadline: "Smart bin<br />Analytics",
      panelSubline: "Welcome back",
      panelText: "Login to continue your smart bin management.",
    },
    dashboard: {
      user: {
        greeting: {
          morning: "Good morning,",
          hello: "Hello,",
          evening: "Good evening,",
        },
      },
      header: {
        title: "Your waste and recycling at a glance",
        stats: {
          avgFill1: "Contamination %",
          avgFill2: "",
          pickup1: "High Contamination",
          pickup2: "",
          bins1: "Restaurants",
          bins2: "",
        },
        filterOptions: {
          "3d": "3 Days",
          "1w": "7 Days",
          "2w": "2 Weeks",
          "4w": "4 Weeks",
          all: "All-Time",
        },
      },
      averageFillChart: {
        yAxisLabel: "Average fill level",
      },
      binsFullPanel: {
        header1: "Bins full",
        header2: "now",
      },
      missedPickupPanel: {
        header1: "Today's missed",
        header2: "pickups",
      },
      totalBagsPanel: {
        header1: "Total waste",
        header2: "generated",
      },
      avgPickupFill: {
        header1: "Average Contamination Level",
        thisMonth: "this month",
      },
      wasteCollectionPanel: {
        header: "Weekly Waste Type Analysis",
      },
    },
    verifyPage: {
      name: "Name",
      password: "Password",
      confirm: "Confirm & Login",
      panelHeadline: "E-mail<br />Confirmed",
      panelSubline: "You're almost in!",
      panelText:
        "Set your personal password to get<br />in touch with the platform.",
    },
    passwordReset: {
      requestForm: {
        headline: "Reset<br />Password",
        subline: "Don't worry!",
        text: "We are here to help you recover your password. Enter the email address you used when you joined, and we’ll send you instructions to reset your password.",
        submitButton: "send link",
      },
      resentForm: {
        headline: "Check your email",
        subline: "Your're almost there!",
        text: "Please look out for our recovery email that we send to your email address:<br /><strong>{{email}}</strong><br />We sent recovery introductions your way.",
        submitButton: "send again",
      },
      passwordForm: {
        headline: "Reset<br />password",
        subline: "Choose your new password",
        text: "We are happy to have you back at work. Enjoy working on our platform as before!",
        submitButton: "save & login",
      },
      steps: {
        address: "Address",
        addressHint: "you joined with.",
        email: "Email",
        emailHint: "with instructions",
        change: "Change",
        changeHint: "your password",
      },
      formLabels: {
        email: "Email",
        newPassword: "New password",
      },
    },
    sensorOverview: {
      header: "Sensors Overview",
      sortBy: "Sort by -",
      sortOptions: {
        fillLevel: "Contamination %",
        name: "Name",
      },
      sensorTile: {
        battery: "Battery",
      },
    },
    sensorDetails: {
      header: {
        filterOptions: {
          "3d": "3 Days",
          "1w": "7 Days",
          "2w": "2 Weeks",
          "4w": "4 Weeks",
          all: "All-Time",
        },
      },
      headerTitle: "Fill level",
      sensorChart: {
        chartTooltip: {
          battery: "Battery",
        },
      },
      settingsButtonLabel: "settings",
      sensorSettingsModal: {
        header: "Sensor Settings",
        nameInputLabel: "Nickname",
        nameInputPlaceholder: "Label for this sensor",
        minDistanceInputLabel: "Distance Sensor to max. Filling",
        minDistanceInputPlaceholder: "Enter your measurements",
        maxDistanceInputLabel: "Distance Sensor to Ground",
        maxDistanceInputPlaceholder: "Enter your measurements",
        cancelButtonLabel: "cancel",
        saveButtonLabel: "save",
      },
    },
    sensorLocations: {
      header: "Sensor Locations",
    },
    settings: {
      header: "Settings",
      navigation: {
        user: "User Details",
        users: "Users",
        schedule: "Sensors Schedule",
        profile: "My Profile",
      },
      userSettings: {
        headerTitle: "Profile",
        firstNameInputLabel: "First Name",
        firstNameInputPlaceholder: "Enter your first name",
        lastNameInputLabel: "Last Name",
        lastNameInputPlaceholder: "Enter your last name",
        emailInputLabel: "Email",
        emailInputPlaceholder: "Enter your email address",
        passwordInputLabel: "Password",
        changePassword: "Change your password?",
        saveButton: "save",
      },
      usersPage: {
        addUserModal: {
          title: "New user",
          firstNameInputLabel: "First Name",
          firstNameInputPlaceholder: "Enter your first name",
          lastNameInputLabel: "Last Name",
          lastNameInputPlaceholder: "Enter your last name",
          emailInputLabel: "E-Mail",
          emailInputPlaceholder: "example@lidbot.com",
          cancelButtonLabel: "cancel",
          addUserButtonLabel: "add user",
        },
      },
      schedulePage: {
        pageHeader: "Schedule",
        scheduleTypeTitle: "Schedule Type",
        scheduleTypeSubtitle: "Fixed intervals or individual schedule approach",
        rateTitle: "Measure fill-levels every",
        rateValueTitle: "Interval",
        rateUnitTitle: "Time Unit",
        saveButton: "save",
      },
    },
    constants: {
      basicUserRoleOptions: {
        user: "user",
        admin: "admin",
        developer: "developer",
        owner: "owner",
      },
      expandedUserRoleOptions: {
        support: "support",
      },
    },
    general: {
      errors: {
        ErrorTitle: "Something went wrong",
      },
    },
  },
};
