import React from 'react';
import style from './sensor-overview-header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setDisplayOption, setSensorOverviewDirection, setSortOption } from '../../../redux/sensor-overview/actions';
import { displayOptionSelector, sensorOverviewDirectionSelector, sortOptionSelector } from '../../../redux/sensor-overview/selectors';
import { DisplayOption, ViewSwitcher } from '../../controls/view-switcher/view-switcher';
import { ReactComponent as TilesIcon } from '../../../assets/img/icons/icon.tiles.svg';
import { ReactComponent as ListIcon } from '../../../assets/img/icons/icon.list.svg';
import { FilterControls, FilterOption } from '../../controls/filter-controls/filter-controls';
import { Trans } from 'react-i18next';
import { SensorListDisplayOption, SensorListOrderDirection } from '../../../redux/sensor-overview/types';
import { AnimatedOptionLabel } from '../slide-in/animated-option-label';
import { Box, Typography } from '@material-ui/core';

export type SensorOverviewSortOption = FilterOption;

export const sortOptions: Array<SensorOverviewSortOption> = [
    {
        label: 'Contamination %l',
        key: 'fill_percentage',
        i18nKey: 'sensorOverview.sortOptions.fillLevel'
    },
    {
        label: 'Name',
        key: 'nickname.keyword',
        i18nKey: 'sensorOverview.sortOptions.name'
    },
    // {
    //     label: 'Bin Model',
    //     key: 'bin_type.name.keyword'
    // },
    {
        label: 'Last Report',
        key: 'last_connected_on'
    },
    {
        label: 'Next Report',
        key: 'next_report'
    }
];

export const displayOptions: Array<DisplayOption> = [
    {
        label: 'Tiles',
        icon: <TilesIcon />,
        key: 'tiles'
    },
    {
        label: 'List',
        icon: <ListIcon />,
        key: 'list'
    }
];

export interface SensorOverviewHeaderProps {
    displayOption?: SensorListDisplayOption
}

export const SensorOverviewHeader: React.FC<SensorOverviewHeaderProps> = (props: SensorOverviewHeaderProps) => {

    const dispatch = useDispatch();

    let activeDisplayOption = useSelector(displayOptionSelector);

    if(props.displayOption) {
        activeDisplayOption = props.displayOption
    }

    const activeSortOption = useSelector(sortOptionSelector);
    const activeSortDirection = useSelector(sensorOverviewDirectionSelector);

    const sortOptionOnClickHandler = (sortOption: SensorOverviewSortOption): void => {
        if (sortOption.key === activeSortOption.key){
            const direction: SensorListOrderDirection = activeSortDirection === 'desc' ? 'asc' : 'desc';
            dispatch(setSensorOverviewDirection(direction));
        }
        else
            dispatch(setSortOption(sortOption));
    };

    const displayOptionClickHandler = (displayOption: DisplayOption): void => {
        dispatch(setDisplayOption(displayOption.key as SensorListDisplayOption));
    };

    const sortDirectionOption = { value: activeSortDirection, label: activeSortDirection === 'desc' ? 'descending' : 'ascending' };
    const animatedLabelDirection = activeSortDirection === 'desc' ? 'down' : 'up';

    return (
        <Box className={style.SensorOverviewHeader}>
            <Box minWidth={250} className={style.sensorOverviewStatus}>
                <Typography variant="h1"><Trans i18nKey='sensorOverview.header'/></Typography>
                <Box className={style.sortStatus}>
                    <span className={style.sortLabel}><Trans i18nKey='sensorOverview.sortBy'/>&nbsp;</span>
                    <span className={style.sortValue}>
                        { activeSortOption.i18nKey ? <Trans i18nKey={activeSortOption.i18nKey} /> : activeSortOption.label}
                        <em>.</em>
                    </span>
                    <AnimatedOptionLabel className={style.sortDirection} option={sortDirectionOption} direction={animatedLabelDirection}/>
                </Box>
            </Box>

            <Box className={style.sensorOverviewSettings}>
                <FilterControls
                    filterOptions={sortOptions}
                    activeFilterOptionKey={activeSortOption.key}
                    activeDirectionOption={activeSortDirection}
                    onFilterOptionClicked={(filterOption) => sortOptionOnClickHandler(filterOption as SensorOverviewSortOption)}
                />
                <ViewSwitcher
                    displayOptions={displayOptions}
                    activeDisplayOptionKey={activeDisplayOption}
                    onDisplayOptionClicked={(displayOption) => displayOptionClickHandler(displayOption)}
                />
            </Box>
        </Box>
    );
};
