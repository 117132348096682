export const restaurants: Restaurant[] = [
  {
    "restaurant": "BIFANAS DE VENDAS NOVAS",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/5c555660-c897-4474-9c5e-a966ddd8eee4.store_499.jpg"
  },
  {
    "restaurant": "GIORNO",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/80d82fae-c73d-4fda-b6bc-df8fd8fe2756.GiornoSite-300x300.jpg"
  },
  {
    "restaurant": "HAAGEN DAZS",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/30e5c720-5a21-4412-aeaf-ef9530000a6a.haagen.jpg"
  },
  {
    "restaurant": "Serra da Estrela",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/02/cb14fa42-a397-44ad-99e6-4945b4eb144c.SE_logotipo.png"
  },
  {
    "restaurant": "ITALIAN REPUBLIC",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/02/8f0135a2-bc48-4fca-89b9-1dbbea8e0053.IR_logotipo_cor.png"
  },
  {
    "restaurant": "SR FRANGO DA GUIA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/01/ea4a3e1a-7aa5-41ea-86ba-d2a84eb33556.sr_.frangoda-guia.png"
  },
  {
    "restaurant": "PORTUGÁLIA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/02/734874b4-1891-41ce-a479-a30ed833c217.Logotipo-Portugalia-2022.jpg"
  },
  {
    "restaurant": "SEN",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2024/01/b3e4a5c8-0e27-4575-8cbf-040c2b9f4ce9.sen_-170x170.png"
  },
  {
    "restaurant": "CHIMARRÃO",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/01/1da3d834-5312-4464-9d15-6bdf1dee19dd.Capture.png"
  },
  {
    "restaurant": "LA BRASSERIE DE lentrecote",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/12/3a6800e1-d428-4664-adcf-8580678b856c.La-Brasserie-de-L%E2%80%99Entrec%C3%B4te.png"
  },
  {
    "restaurant": "CASA MIA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/12/b8613e53-fcc4-47b9-abf6-8c50362b312b.LOGO_CASA_MIA-2-170x170.jpg"
  },
  {
    "restaurant": "TASTE OF INDIA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/aec5c8e4-0d76-422a-84e8-0d03eb4f0c47.taste-in-india.jpg"
  },
  {
    "restaurant": "SABORES DA ILHA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/05/3b727104-0d6c-4f91-b66a-f7a7c4f626a0.ilha_.jpg"
  },
  {
    "restaurant": "A FÁBRICA DA NATA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/08/b8619f85-2130-4b61-a322-12bff6cd2669.Logotipo_F%C3%A1brica-da-Nata.png"
  },
  {
    "restaurant": "MCDONALDS",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/c44ceeb9-5c65-4893-a331-544239bb7ccc.McDonalds-200x200.png"
  },
  {
    "restaurant": "PANS AND COMPANY",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/160ef4cb-37e8-443a-b11a-5f066741c48a.NEW-logo-PANS-300x300.jpg"
  },
  {
    "restaurant": "PATEO COLOMBO",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/01/5251121c-dee1-4aef-9337-b07704f3b93a.logo_pateo2_novo.jpg"
  },
  {
    "restaurant": "KENTUCKY FRIED CHICKEN",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2020/02/11e81738-1741-4320-b558-d26d2b37c0f6.KFC_.png"
  },
  {
    "restaurant": "A PADARIA PORTUGUESA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2024/07/A-Padaria-Portuguesa-2022-170x170.png"
  },
  {
    "restaurant": "QUIOSQUE DOS SABORES",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/46929e8f-aa2c-4c92-a119-cdd162f62c37.Quiosque-dos-Sabores.png"
  },
  {
    "restaurant": "SUMINHO",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/06/9bd34786-cc6b-43f6-8473-92b467f4bb2a.AF-LOGO-SUMINHO.jpg"
  },
  {
    "restaurant": "VERSAILLES",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/4a9cb50e-9b61-4306-a89d-9cd969de570e.versailles-200x183.png"
  },
  {
    "restaurant": "JERONYMO",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/05/f3899f71-35a5-4815-bc99-7809167160ff.logo_simples.png"
  },
  {
    "restaurant": "ICE IT",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/d1116cc8-fe8c-4290-93ac-17f1a22a66d5.perfil.png"
  },
  {
    "restaurant": "IOGU",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/04/fec430a2-a365-4516-96ea-aa7768931ca3.iogu-logo-JPEG.jpg"
  },
  {
    "restaurant": "SABORES DE CINTRA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/f5c6365c-e55c-4bf9-97da-9808fcaef08d.sabores-de-cintra.jpg"
  },
  {
    "restaurant": "AMOR AOS PEDAÇOS",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/728b7383-6c90-4fbb-9dea-bad3e33f2501.site-220-x-2202.png"
  },
  {
    "restaurant": "NOORI SUSHI",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/f0473b0c-e442-4586-98f8-1f6452eb24ae.Noori-Sushi-200x200.png"
  },
  {
    "restaurant": "GUACAMOLE",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/c4aeb717-d5f9-4577-92ad-6c7ee57458f6.logo-site4.png"
  },
  {
    "restaurant": "SELFISH",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/51044a2a-3198-4e21-8843-4e4b974c9d6d.selfish-site-300x300.png"
  },
  {
    "restaurant": "MOTHER BURGER",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/02/48013b5f-615c-4a70-a9b5-11fd02b98554.0613126b-437d-425a-a545-3ce959182be0.jpeg"
  },
  {
    "restaurant": "LOJA DAS SOPAS",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/9a37eab2-25cf-4824-a996-027c7caae306.loja-das-sopas-200x105.jpg"
  },
  {
    "restaurant": "Taco Bell",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/04/5f9d2a71-a543-4f4c-95c4-107217d299e2.LOGO-TACO-BELL-560x560-1.png"
  },
  {
    "restaurant": "BURGER KING",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/12/c9743173-3b26-4029-9b05-8a1b6b1b65f6.LOGO-BK2.png"
  },
  {
    "restaurant": "JANGO TASTE OF AFRICA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2022/10/f06b4d69-2cd0-4bc0-b891-4b0a32666f5b.Jango-Light-Blue-Vertical_page-0001-1-170x170.jpg"
  },
  {
    "restaurant": "Poke House",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2021/01/fb7d2ad5-cdd6-4c5f-9fb2-d057f496596e.Pok%C3%A9House.png"
  },
  {
    "restaurant": "CAFÉ3 COZINHA SAUDÁVEL",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/70d269ea-f3d8-4af9-8c01-6a702dc59bee.logo-cafe3-200x200.jpg"
  },
  {
    "restaurant": "OLÁ",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/42504152-5f94-4b71-8c7a-aa26d27eff11.ola_logo.png"
  },
  {
    "restaurant": "WOK TO WALK",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2024/07/CAPA_IG_ABR24-170x170.jpg"
  },
  {
    "restaurant": "MR PIZZA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/4f77202d-8a16-41e9-ad21-3a111f8bd1f3.mr_.pizza_.jpg"
  },
  {
    "restaurant": "H3 HAMBÚRGUER GOURMET",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/33a020f2-fe30-4eec-ad4f-d477e49cbced.H3-200x200.jpg"
  },
  {
    "restaurant": "CAPRI",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/28cc7a7b-641a-455a-a1f9-e938bdabe950.novo-logo-out15-300x300.png"
  },
  {
    "restaurant": "LA PARRILLA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/64b4fcfe-5462-4dbe-9017-1a52f81d74e1.la-parrilla.jpg"
  },
  {
    "restaurant": "LI YUAN",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/03/bb5ed02a-3165-4975-8426-e83360c5b269.li-yaun.jpg"
  },
  {
    "restaurant": "VITAMINAS AND COMPANHIA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/b734c5d0-50d2-4e5d-8ab1-ceb48a36fc29.Vitaminas.png"
  },
  {
    "restaurant": "JOSHUAS SHOARMA",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/404206fc-f31c-4675-adee-259f25e08f51.Joshuas-Shoarma.png"
  },
  {
    "restaurant": "GO NATURAL",
    "logo": "https://gonatural.pt/cdn/shop/files/Untitled-1_dc1182bc-bf9b-4060-968d-3511e1891586.jpg?v=1706885825"
  },
  {
    "restaurant": "PIZZA HUT",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2020/11/28ad08b4-0998-468e-a52b-dde1b7e7c8e5.pizza_.jpg"
  },
  {
    "restaurant": "SUSHI CORNER",
    "logo": "https://media.slbenfica.pt/-/media/benficadp/shop/members/red-power/partners/sushi-corner/350x260px.png?h=260&w=350&v=637057200450000000&hash=882C27C8354C92CF79C946ED5C519E69"
  },
  {
    "restaurant": "FARGGI",
    "logo": "https://sonaesierracms-v2.cdnpservers.net/wp-content/uploads/sites/14/2019/02/6559df9f-926d-44a2-a04f-b011f21186af.Farggi-200x200.png"
  },
  {
    "restaurant": "O Forno do Leitão do Zé",
    "logo": "https://leitaodoze.pt/wp-content/uploads/2021/03/Logo-Black-Menu.png"
  }
]

export interface Restaurant {
  restaurant: string;
  logo: string;
}

const normalizeString = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

export function getLogoUrl(name: string | undefined, sensorId: string): string | undefined {
  const normalizedPropsName = name ? normalizeString(name.replace(/_/g, ' ')) : '';
  const normalizedSensorId = normalizeString(sensorId);

  const restaurant = restaurants.find((restaurant) => {
    const normalizedRestaurantName = normalizeString(restaurant.restaurant);
    return normalizedRestaurantName === normalizedPropsName || normalizedRestaurantName === normalizedSensorId;
  });

  return restaurant?.logo;
}